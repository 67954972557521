import axios from "axios"
import { useMsal } from "@azure/msal-react"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material"
import { ViewFaculty } from "../faculty/FacultySearch"
import { ViewStudent } from "../students/StudentsSearch"
import { Email, EmailAttachment, EmailScheduleRule, Filters, Recipient } from "./types"
import { Warning, MarkEmailRead } from '@mui/icons-material'
import VariablesTable from "./VariablesTable"
import Schedule from "./Schedule"
import EmailFilters from "./EmailFilters"
import EmailAttachments from "./EmailAttachments"
import ScheduleRules from "./ScheduleRules"
import { Group, AttachFile, TextFields, CalendarMonth, Science, Send, Save, Mail } from '@mui/icons-material'
import NewEmail from "./NewEmail"

export type EmailTabs = 'filters' | 'variables' | 'schedule' | 'attachments' | 'email'

export type ExtraVariablesMap = { name: string, placeholder: string }

export default function SendEmail() {
  // Hooks
  const { accounts } = useMsal()
  const username = useMemo(() => accounts[0]?.username || 'Cypress testing', [accounts])
  // General
  const [tested, setTested] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [visualizeHTML, setVisualizeHTML] = useState(false)
  const [tab, setTab] = useState<EmailTabs>('email')
  const [isHTML, setIsHTML] = useState<boolean>(false)
  // Data
  const [email, setEmail] = useState<Email>({
    sender: username || '',
    subject: '',
    message: ''
  })

  const [recipients, setRecipients] = useState<ViewFaculty[] | ViewStudent[]>([])
  const [scheduleList, setScheduleList] = useState<string[]>([])
  const [recipientsType, setRecipientsType] = useState<Recipient>('students')
  const [recipientIds, setRecipientIds] = useState<number[]>([])
  const [attachments, setAttachments] = useState<EmailAttachment[]>([])
  const [scheduleRules, setScheduleRules] = useState<EmailScheduleRule[]>([])
  const [isRecurrentEmail, setIsRecurrentEmail] = useState(false)
  const [extraVariables, setExtraVariables] = useState<object[]>([])

  const actionButtons = [
    {
      id: 'action-button-email',
      icon: <Mail />,
      label: 'New Email',
      action: () => setTab('email'),
      width: 1.2
    },
    {
      id: 'action-button-recipients',
      icon: <Group />,
      label: 'Recipients',
      action: () => setTab('filters'),
      width: 1.2
    },
    {
      id: 'action-button-attachments',
      icon: <AttachFile />,
      label: 'Attachments',
      action: () => setTab('attachments'),
      width: 1.2
    },
    {
      id: 'action-button-variables',
      icon: <TextFields />,
      label: 'Variables',
      action: () => setTab('variables'),
      width: 1.2
    },
    {
      id: 'action-button-schedule',
      icon: <CalendarMonth />,
      label: 'Schedule',
      action: () => setTab('schedule'),
      width: 1.2
    }
  ]

  const renderComponent = () => {
    switch (tab) {
      case 'email':
        return <NewEmail isHTML={isHTML} sendRules={sendRules} visualizeHTML={visualizeHTML} setVisualizeHTML={setVisualizeHTML} email={email} setEmail={setEmail} username={username} />
      case 'filters':
        return <EmailFilters
          recipientIds={recipientIds}
          recipients={recipients}
          recipientsType={recipientsType}
          setRecipients={setRecipients}
          setRecipientIds={setRecipientIds}
          setRecipientsType={setRecipientsType}
          tab={tab}
        />
      case 'variables':
        return <VariablesTable
          receivers={recipientIds}
          extraVariables={extraVariables}
          setExtraVariables={setExtraVariables}
          recipientsType={recipientsType}
          data={recipients}
        />
      case 'schedule':
        return <>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Is recurring E-mail?'}
              control={
                <Checkbox
                  id='form-field-is-recurring-email'
                  checked={isRecurrentEmail}
                  onChange={(e) => setIsRecurrentEmail(e.target.checked)}
                />}
            />
          </Grid>
          {
            isRecurrentEmail ?
              <ScheduleRules isRecurrentEmail={isRecurrentEmail} scheduleRules={scheduleRules} setScheduleRules={setScheduleRules} />
              :
              <Schedule isRecurrentEmail={isRecurrentEmail} schedule={scheduleList} setSchedule={setScheduleList} />
          }
        </>
      case 'attachments':
        return <EmailAttachments
          attachments={attachments}
          setAttachments={setAttachments}
        />
      default:
        return <></>
    }
  }

  const sendRules = useMemo(() => {
    if (!email.message || !email.subject || !email.sender) {
      return {
        message: 'E-mail form is empty. Insert sender, subject and message.',
        send: true,
        test: true,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    if (!recipientIds.length) {
      return {
        message: 'Recipients list is empty. Combine filters and manage the list.',
        send: true,
        test: false,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    if (!tested) {
      return {
        message: 'Before sending the e-mail, you need to test to yourself.',
        send: true,
        test: false,
        color: '#D4A200',
        icon: <Warning />
      }
    }
    return {
      message: scheduleList.length ? 'Ready to schedule you e-mail.' : 'Ready to send your e-mail.',
      send: false,
      test: false,
      color: '#2F8F46',
      icon: <MarkEmailRead />
    }
  }, [email, recipientIds, tested, scheduleList])

  useEffect(() => {
    setTested(false)
  }, [email])

  const sendEmail = useCallback(async (test: boolean) => {

    //if isn't a html content, change to
    let _email = {
      ...email,
      message: !isHTML ? email.message.split('\n').map((_line) => `<p>${_line}</p>`).join('\n') : email.message
    }

    setDisabledButton(true)

    const _toast = toast(`${test ? `Sending test e-mail to ${username}` : 'Sending e-mail'}`, { toastId: 'toast-send-email', isLoading: true })

    try {
      const { data } = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/send-email-now`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
        data: {
          email: _email,
          username: username,
          recipientsType,
          recipientIds,
          extraVariables,
          test,
          attachments
        }
      })

      console.log(data)

      if (test) setTested(true)

      toastSuccess(_toast, 'E-mail sent.')
      setDisabledButton(false)
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not send e-mail.')
      setDisabledButton(false)
    }

  }, [email, extraVariables, recipientIds, recipientsType, username, attachments])

  const scheduleEmails = useCallback(async () => {
    let _email = {
      ...email,
      message: !isHTML ? email.message.split('\n').map((_line) => `<p>${_line}</p>`).join('\n') : email.message
    }

    setDisabledButton(true)

    const _toast = toast(`Scheduling e-mail`, { toastId: 'toast-scheduling-email', isLoading: true })

    try {
      await axios({
        method: 'POST',
        url: scheduleRules.length ? `${process.env.REACT_APP_NOTIFIER_URL}/schedules-rules` : `${process.env.REACT_APP_NOTIFIER_URL}/schedules`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
        data: {
          email: _email,
          username: username,
          email_schedules: scheduleList.length > 0 ? scheduleList : scheduleRules,
          recipientsType,
          recipientIds,
          extraVariables: JSON.stringify(extraVariables),
          attachments
        }
      })

      toastSuccess(_toast, 'Scheduled e-mail saved.')

      setDisabledButton(false)
    } catch (error) {
      console.log(error)
      setDisabledButton(false)
      toastError(_toast, 'Could not save scheduled e-mail.')
    }
  }, [email, extraVariables, scheduleList, recipientIds, recipientsType, username, attachments, scheduleRules])


  return <>
    <Grid item xs={12}>
      <Grid container spacing={1}>
        {
          actionButtons.map((item, index) => (
            <Grid item xs={item.width} key={index}>
              <Button
                sx={{ height: 35 }}
                key={index}
                id={item.id}
                onClick={item.action}
                startIcon={item.icon}
              >
                {item.label}
              </Button>
            </Grid>
          ))
        }
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                id='checkbox-is-html-email'
                checked={isHTML}
                onChange={(e) => setIsHTML(e.target.checked)}
                color="primary"
              />
            }
            label="Is an HMTL Email"
          />
        </Grid>
        {/* spacer */}
        <Grid item xs={2} />
        {/* spacer */}
        <Grid item xs={1}>
          <Button
            id='button-test-email'
            onClick={() => sendEmail(true)}
            disabled={sendRules.test || disabledButton} startIcon={<Science />}>
            Test
          </Button>
        </Grid>
        <Grid item xs={1}>
          {
            scheduleList.length || scheduleRules.length ?
              <Button
                id='button-save'
                onClick={() => scheduleEmails()}
                disabled={sendRules.send || disabledButton}
                startIcon={<Save />}
              >
                Save
              </Button>
              :
              <Button
                id='button-send'
                onClick={() => sendEmail(false)}
                disabled={sendRules.send || disabledButton}
                startIcon={<Send />}
              >
                Send
              </Button>
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} maxHeight={'80vh'}>
            {renderComponent()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
}

