import { BuildFilters, EmailVariables } from "./types"

export const variables: EmailVariables[] = [
    { name: 'Student full name', column: 'student_full_name', variable: '%student_full_name', type: 'students' },
    { name: 'Student first name', column: 'first_name', variable: '%first_name', type: 'students' },
    { name: 'Student last name', column: 'last_name', variable: '%last_name', type: 'students' },
    { name: 'Faculty full name', column: 'full_name', variable: '%full_name', type: 'faculty' },
    { name: 'Faculty first name', column: 'first_name', variable: '%first_name', type: 'faculty' },
    { name: 'Faculty last name', column: 'last_name', variable: '%last_name', type: 'faculty' },
    { name: 'Guardian name', column: 'guardian_name', variable: '%guardian_name', type: 'parents' },
    { name: 'Additional guardian name', column: 'additional_guardian_name', variable: '%additional_guardian_name', type: 'parents' },
    { name: 'Student full name', column: 'student_full_name', variable: '%student_full_name', type: 'parents' },
    { name: 'Student first name', column: 'first_name', variable: '%first_name', type: 'parents' },
    { name: 'Student last name', column: 'last_name', variable: '%last_name', type: 'parents' }
]

export const buildFilters: BuildFilters[] = [
    { id: 'search-filter-affiliation', label: 'Affiliation', key: 'fk_id_affiliation', width: 3, type: 'all' },
    { id: 'search-filter-student-status-type', label: 'Status', key: 'fk_id_student_status_type', width: 2, type: 'students' },
    { id: 'search-filter-grade', label: 'Grade', key: 'fk_id_grade', width: 2, type: 'students' },
    { id: 'search-filter-program', label: 'Program', key: 'fk_id_program', width: 2, type: 'students' },
    { id: 'search-filter-student-status-type', label: 'Status', key: 'fk_id_student_status_type', width: 2, type: 'parents' },
    { id: 'search-filter-grade', label: 'Grade', key: 'fk_id_grade', width: 2, type: 'parents' },
    { id: 'search-filter-program', label: 'Program', key: 'fk_id_program', width: 2, type: 'parents' },
    { id: 'search-filter-faculty-type', label: 'Role', key: 'fk_id_faculty_type', width: 3, type: 'faculty' }
]

export const studentQueryBase = 'SELECT id, student_full_name, first_name, last_name, email, guardian_name, additional_guardian_name, additional_guardian_email, guardian_email FROM view_student_2'

export const facultyQueryBase = 'SELECT id, faculty_full_name, first_name, last_name, email FROM view_faculty'

export const recipientTypeSelect = [
    { label: 'Students', value: 'students' },
    { label: 'Faculty', value: 'faculty' },
    { label: 'Parents', value: 'parents' }
]
