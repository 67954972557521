import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Autocomplete, Button, Dialog, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField } from '@mui/material'
import { BundlesTable, CustomersTable } from './AssignBundlesTable'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { quickSort } from '../../Utils'
import { RightSidePanel } from 'wed-components'
import AssignBundlesDetails from './AssignBundlesDetails'
import { Add, AddCircle, FilterAlt } from '@mui/icons-material'
import CashFlow from './CashFlow'
import { toastError, toastSuccess } from '../../assets/customToasts'
import { DateTime } from 'luxon'
import { ViewBundle, ViewCustomer } from './types'
import AssignBundlesFilters from './AssignBundlesFilters'

export type SuggestedBundles = {
  fk_id_program: number
  program_name: string
  fk_id_voucher_type: number
  voucher_type_name: string
  voucher_type_tag: string
  quantity: number
}

export type AssignedVouchers = {
  beneficiary_dependence_id: number
  bundle: {
    id: number
    bundle_name: string
    voucher_amount: number
  }
  fk_id_bundle: number
}

export type ExpectedVouchers = {
  affiliation_course_progression_name: string | null
  affiliation_course_progression_tag: string | null
  assigned_voucher_quantity: number
  credits: number
  expected_voucher_quantity: number
  fk_id_affiliation_course_progression: string | null
  fk_id_program: number
  fk_id_student: number
  fk_id_student_status_type?: number
  fk_id_voucher_type: number
  program_name: string
  student_status_type_name?: string
  voucher_type_name: string
  voucher_type_tag: string
}

type AutocompleteType = null | { id: number, label: string }

type BundlesFilter = {
  affiliation: AutocompleteType
  year: number
}

export type CustomerFilters = {
  affiliation: AutocompleteType
  grade: 'all' | number
  program: 'all' | number
  credits: 'all' | number
  status: string
  hideWithBundle: boolean
  hideAssignedEqualExpected: boolean
}

export type SortCustomers = {
  column: keyof ViewCustomer
  direction: 'asc' | 'desc'
}

export type Panel = {
  show: boolean
  fk_id_student?: number
}

export type Modal = {
  show: boolean
  fk_id_bundle?: number
  customers?: number[]
}

export default function AssignBundles2() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<HTMLElement | null>(null)
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false)
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  const [modal, setModal] = useState<Modal>({
    show: false
  })
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)
  // Data
  const [bundles, setBundles] = useState<ViewBundle[]>([])
  const [selectedBundleId, setSelectedBundleId] = useState<number | null>(null)
  const [customers, setCustomers] = useState<ViewCustomer[]>([])
  const [selectedCustomers, setSelectedCustomers] = useState<number[]>([])
  const [assignedAndExpectedVouchers, setAssignedAndExpectedVouchers] = useState<ExpectedVouchers[]>([])
  // Filters
  const [bundlesFilters, setBundlesFilters] = useState<BundlesFilter>({
    affiliation: null,
    year: DateTime.utc().get('year')
  })
  const [customerFilters, setCustomerFilters] = useState<CustomerFilters>({
    affiliation: null,
    grade: 'all',
    program: 'all',
    credits: 'all',
    status: 'active',
    hideWithBundle: false,
    hideAssignedEqualExpected: false
  })
  const [sort, setSort] = useState<SortCustomers>({
    column: 'customer_grade_id',
    direction: 'asc'
  })
  // useMemos
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])

  const gradeOptions = useMemo(() => {
    const availableGrades = customers.reduce((prev: number[], curr) => {
      if (curr.customer_grade_id && !prev.includes(curr.customer_grade_id)) prev.push(curr.customer_grade_id)

      return prev
    }, [])

    return lookupTables.grade.filter((_) => availableGrades.includes(_.id))
  }, [lookupTables, customers])

  const programOptions = useMemo(() => {
    const availablePrograms = customers.reduce((prev: number[], curr) => {
      if (curr.customer_program_id && !prev.includes(curr.customer_program_id)) prev.push(curr.customer_program_id)

      return prev
    }, [])

    return lookupTables.program.filter((_) => availablePrograms.includes(_.id))
  }, [lookupTables, customers])

  const creditsOptions = useMemo(() => assignedAndExpectedVouchers
    .map((_) => _.credits)
    .filter((_, index, self) => self.indexOf(_) === index)
    .sort((a, b) => a - b),
    [assignedAndExpectedVouchers])

  const getBundles = useCallback(async () => {
    const _toast = toast('Loading bundles', { toastId: 'toast-assign-bundles-loading-bundles', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      setBundles(data)

      toastSuccess(_toast, 'Bundles loaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch bundles.')
    }
  }, [])

  useEffect(() => {
    getBundles()
  }, [getBundles])

  // Fetch customers only if an affiliation was selected from the customers' filters
  const getCustomers = useCallback(async () => {
    console.log('loading customers again')
    setCustomers([])

    // Render helper text if no affiliation was selected
    if (!customerFilters.affiliation) return

    setLoadingCustomers(true)

    const _toast = toast('Loading customers', { toastId: 'toast-assign-bundles-loading-customers', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: bundlesFilters.year === 2025 ? `${process.env.REACT_APP_REQUESTS_URL}/get-confirmed-students?fk_id_affiliation=${customerFilters.affiliation.id}` : `${process.env.REACT_APP_SIS_BACKEND_URL}/customers?fk_id_affiliation=${customerFilters.affiliation.id}&fk_id_customer_type=2&${customerFilters.status === `active` ? [1, 6, 8].map((_) => `fk_id_student_status_type[]=${_}`).join('&') : `fk_id_student_status_type[]=${customerFilters.status}`}`,
        headers: {
          Authorization: bundlesFilters.year === 2025 ? `Bearer ${process.env.REACT_APP_REQUEST_TOKEN}` : `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log('customers', data)

      setSelectedCustomers([])

      setCustomers(data)

      toastSuccess(_toast, 'Customers loaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not fetch customers.')
    }

    setLoadingCustomers(false)
  }, [customerFilters.affiliation, customerFilters.status, setCustomers, setSelectedCustomers, setLoadingCustomers, bundlesFilters])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const getExpectedAndAssignedBundles = useCallback(async () => {
    setAssignedAndExpectedVouchers([])

    if (!customerFilters.affiliation) return

    try {
      const { data: expectedInCourseProgression }: { data: ExpectedVouchers[] } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/assigned-and-expected-vouchers?fk_id_affiliation=${customerFilters.affiliation.id}&year=${bundlesFilters.year}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(expectedInCourseProgression)

      setAssignedAndExpectedVouchers(expectedInCourseProgression)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch suggested bundles.')
    }
  }, [bundlesFilters.year, customerFilters.affiliation, setAssignedAndExpectedVouchers])

  useEffect(() => {
    getExpectedAndAssignedBundles()
  }, [getExpectedAndAssignedBundles])

  // Bundles Filters
  const filterBundleByAffiliation = useCallback((bundle: ViewBundle) => {
    switch (bundlesFilters.affiliation) {
      case null:
        return true
      default:
        return bundle.fk_id_affiliation === bundlesFilters.affiliation.id || bundle.default_billings[0]?.fk_id_affiliation === bundlesFilters.affiliation.id
    }
  }, [bundlesFilters.affiliation])

  const filterBundleByYear = useCallback((bundle: ViewBundle) => {
    return bundle.reference_year === bundlesFilters.year
  }, [bundlesFilters.year])

  const filteredBundles = useMemo(() => {
    return bundles.filter(filterBundleByAffiliation).filter(filterBundleByYear)
  }, [bundles, filterBundleByAffiliation, filterBundleByYear])

  // Customers Filters
  const filterCustomerByGrade = useCallback((customer: ViewCustomer) => {
    switch (customerFilters.grade) {
      case 'all':
        return true
      default:
        return customerFilters.grade === customer.customer_grade_id
    }
  }, [customerFilters.grade])

  const filterCustomerByProgram = useCallback((customer: ViewCustomer) => {
    switch (customerFilters.program) {
      case 'all':
        return true
      default:
        return customerFilters.program === customer.customer_program_id
    }
  }, [customerFilters.program])

  const filterCustomerByAssignedEqualExpected = useCallback((customer: ViewCustomer) => {
    switch (customerFilters.hideAssignedEqualExpected) {
      case true:
        const assignedAndExpected = assignedAndExpectedVouchers.filter((_) => _.fk_id_student === customer.fk_id_customer_dependence)

        if (!assignedAndExpected.length) return false

        if (assignedAndExpected.length === 1) return assignedAndExpected[0].assigned_voucher_quantity !== assignedAndExpected[0].expected_voucher_quantity

        return assignedAndExpected.reduce((prev, curr) => prev += curr.assigned_voucher_quantity, 0) !== assignedAndExpected.reduce((prev, curr) => prev += curr.expected_voucher_quantity, 0)
      default:
        return true
    }
  }, [customerFilters.hideAssignedEqualExpected, assignedAndExpectedVouchers])

  // This filter is to prevent applicants for the next year show up in the current year
  const filterCustomersWithoutCourseProgression = useCallback((customer: ViewCustomer) => {
    const beneficiaryIds = assignedAndExpectedVouchers.map((_) => _.fk_id_student)

    return beneficiaryIds.includes(customer.fk_id_customer_dependence)
  }, [assignedAndExpectedVouchers])

  const filteredCustomers = useMemo(() => {
    return quickSort(
      customers
        .filter(filterCustomerByProgram)
        .filter(filterCustomerByGrade)
        .filter(filterCustomerByAssignedEqualExpected)
        .filter(filterCustomersWithoutCourseProgression)
      ,
      sort.column,
      sort.direction
    )
  }, [customers, filterCustomerByGrade, filterCustomerByProgram, filterCustomerByAssignedEqualExpected, filterCustomersWithoutCourseProgression, sort])

  // When the Bundles affiliation filter changes
  useEffect(() => {
    setSelectedBundleId(null)
    setCustomerFilters((_customerFilters) => ({ ..._customerFilters, affiliation: bundlesFilters.affiliation }))
  }, [bundlesFilters.affiliation])

  useEffect(() => {
    if (modal.show) setActionsMenuAnchor(null)
  }, [modal, setActionsMenuAnchor])

  return <>
    {/* Modal */}
    <Dialog
      id='modal-assign-bundle'
      open={modal.show}
      onClose={() => setModal({ show: false })}
      maxWidth='xl'
    >
      <Grid container maxWidth='xl'>
        <CashFlow
          bundleId={modal.fk_id_bundle}
          customerIds={modal.customers}
          onClose={() => setModal({ show: false })}
        />
      </Grid>
    </Dialog>
    {/* RightSidePanel */}
    <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title='Assign Bundles details' maxWidth='lg'>
      {
        panel.show ?
          <AssignBundlesDetails
            panel={panel}
            year={bundlesFilters.year}
          />
          :
          <></>
      }
    </RightSidePanel>
    {/* Bundles */}
    <Grid item xs={3.95}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Autocomplete
            id='bundles-filter-affiliation'
            options={affiliationOptions}
            renderInput={(params) => <TextField {...params} label='Affiliation' />}
            value={bundlesFilters.affiliation}
            onChange={(event, newValue) => setBundlesFilters({ ...bundlesFilters, affiliation: newValue })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='bundles-filter-year'
            label='Year'
            value={bundlesFilters.year}
            onChange={(e) => setBundlesFilters({ ...bundlesFilters, year: Number(e.target.value) })}
            select
          >
            <MenuItem value='2023'>2023</MenuItem>
            <MenuItem value='2024'>2024</MenuItem>
            <MenuItem value='2025'>2025</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} maxHeight={'76vh'}>
          <BundlesTable
            data={filteredBundles}
            selectedBundleId={selectedBundleId}
            setSelectedBundleId={setSelectedBundleId}
          />
        </Grid>
      </Grid>
    </Grid>
    {/* Spacer */}
    <Grid item xs={.1} />
    {/* Customers */}
    <Grid item xs={7.95}>
      <Grid container spacing={1}>
        <Grid item xs={3.25}>
          <Autocomplete
            id='customer-filter-affiliation'
            options={affiliationOptions}
            renderInput={(params) => <TextField {...params} label='Affiliation' />}
            value={customerFilters.affiliation}
            onChange={(event, newValue) => setCustomerFilters({ ...customerFilters, affiliation: newValue })}
          />
        </Grid>
        <Grid item xs={1.75}>
          <TextField
            id='bundles-filter-grade'
            label='Grade'
            value={customerFilters.grade}
            onChange={(e) => setCustomerFilters({ ...customerFilters, grade: e.target.value === 'all' ? 'all' : Number(e.target.value) })}
            select
          >
            <MenuItem key={-1} value='all'>All</MenuItem>
            {
              gradeOptions.map((_grade, index) => <MenuItem key={index} id={`grade-option-${_grade.id}`} value={_grade.id}>{_grade.option}</MenuItem>)
            }
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id='bundles-filter-program'
            label='Program'
            value={customerFilters.program}
            onChange={(e) => setCustomerFilters({ ...customerFilters, program: e.target.value === 'all' ? 'all' : Number(e.target.value) })}
            select
          >
            <MenuItem key={-1} value='all'>All</MenuItem>
            {
              programOptions.map((_program, index) => <MenuItem key={index} id={`program-option-${_program.id}`} value={_program.id}>{_program.option}</MenuItem>)
            }
          </TextField>
        </Grid>
        <Grid item xs={1.5}>
          <TextField
            id='bundles-filter-credits'
            label='Credits'
            value={customerFilters.credits}
            onChange={(e) => setCustomerFilters({ ...customerFilters, credits: e.target.value === 'all' ? 'all' : Number(e.target.value) })}
            select
          >
            <MenuItem id={`credits-option-all`} value='all'>All</MenuItem>
            {
              creditsOptions.map((_credits, index) => <MenuItem key={index} id={`credits-option-${_credits}`} value={_credits}>{_credits.toFixed(1)}</MenuItem>)
            }
          </TextField>
        </Grid>
        <Grid item xs={1.5}>
          <Button
            id='bundles-filter-button-more'
            startIcon={<FilterAlt />}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
          >
            More
          </Button>
          <AssignBundlesFilters
            customerFilters={customerFilters}
            menuAnchor={menuAnchor}
            setCustomerFilters={setCustomerFilters}
            setMenuAnchor={setMenuAnchor}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            id='button-actions'
            startIcon={<AddCircle />}
            onClick={(e) => setActionsMenuAnchor(e.currentTarget)}
            disabled={!selectedCustomers.length}
          >
            Actions
          </Button>
        </Grid>
        {/* Menu */}
        <Menu
          id='menu-actions'
          open={Boolean(actionsMenuAnchor)}
          anchorEl={actionsMenuAnchor}
          onClose={() => setActionsMenuAnchor(null)}
          slotProps={{ paper: { sx: { padding: 0 } } }}
        >
          <MenuList>
            <MenuItem
              id='actions-option-assign-bundle'
              onClick={() => setModal({ show: true, fk_id_bundle: selectedBundleId!, customers: selectedCustomers })}
              disabled={!Boolean(selectedBundleId)}
            >
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              <ListItemText>
                Assign Bundle
              </ListItemText>
            </MenuItem>

            <MenuItem
              id='actions-option-create-billing'
              onClick={() => setModal({ show: true, customers: selectedCustomers })}
            >
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText>
                Create Billing
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
        <Grid item xs={12} maxHeight={`calc(70vh + ${selectedCustomers.length * 50}px)`}>
          <CustomersTable
            data={customers.filter(filterCustomersWithoutCourseProgression)}
            filteredData={filteredCustomers}
            loadingCustomers={loadingCustomers}
            selectedCustomers={selectedCustomers}
            setSelectedCustomers={setSelectedCustomers}
            assignedAndExpectedVouchers={assignedAndExpectedVouchers}
            sort={sort}
            setSort={setSort}
            setPanel={setPanel}
          />
        </Grid>
      </Grid>
    </Grid>
  </>
}
