import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Button, Grid, MenuItem, TextField } from '@mui/material'
import axios from 'axios'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { ViewDefaultBilling } from './DefaultBillings'
import { DriveFileRenameOutline, Save, Update } from '@mui/icons-material'
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../../assets/customToasts'

type DefaultBillingsFormProps = {
  defaultBilling: ViewDefaultBilling | DefaultBillingForm | null
  closePanel: () => void
  refreshTable: () => void
  duplicating: boolean | undefined
  selectedYear: number | 'all'
}

export type DefaultBillingForm = {
  id?: number | null
  default_billing_name: string | null
  default_value: string
  fk_id_currency: number | null
  max_installments: number | null
  fk_id_affiliation: number | null
  fk_id_program: number | null
  fk_id_service: number | null
  fk_id_payment_method: number | null
  fk_id_payee: number | null
  fk_id_customer_type: number | null
  commercial_conditions: string | null
}

export const formInitialState: DefaultBillingForm = {
  id: null,
  default_billing_name: '',
  default_value: '',
  fk_id_currency: 5, // BRL
  max_installments: null,
  fk_id_affiliation: null,
  fk_id_program: null,
  fk_id_service: null,
  fk_id_payment_method: 2, // Bank bill
  fk_id_payee: 1955, // TESI
  fk_id_customer_type: 1, // Affiliation
  commercial_conditions: '',
}

export default function DefaultBillingsForm({ defaultBilling, closePanel, refreshTable, duplicating, selectedYear }: DefaultBillingsFormProps) {
  // General
  const [disableSave, setDisableSave] = useState<boolean>(false)
  // Data
  const [form, setForm] = useState<DefaultBillingForm>(formInitialState)
  // Hooks
  const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
  // useEffects
  useEffect(() => {
    // Duplicating
    if (duplicating && defaultBilling) {
      setForm({
        default_billing_name: defaultBilling.default_billing_name,
        default_value: Number(defaultBilling.default_value).toFixed(2),
        fk_id_currency: defaultBilling.fk_id_currency,
        max_installments: defaultBilling.max_installments,
        fk_id_affiliation: defaultBilling.fk_id_affiliation,
        fk_id_program: defaultBilling.fk_id_program,
        fk_id_service: defaultBilling.fk_id_service,
        fk_id_payment_method: defaultBilling.fk_id_payment_method,
        fk_id_payee: defaultBilling.fk_id_payee,
        fk_id_customer_type: defaultBilling.fk_id_customer_type,
        commercial_conditions: defaultBilling.commercial_conditions,
      })
    } else if (defaultBilling) {
      // Editing
      setForm({
        id: defaultBilling.id,
        default_billing_name: defaultBilling.default_billing_name,
        default_value: Number(defaultBilling.default_value).toFixed(2),
        fk_id_currency: defaultBilling.fk_id_currency,
        max_installments: defaultBilling.max_installments,
        fk_id_affiliation: defaultBilling.fk_id_affiliation,
        fk_id_program: defaultBilling.fk_id_program,
        fk_id_service: defaultBilling.fk_id_service,
        fk_id_payment_method: defaultBilling.fk_id_payment_method,
        fk_id_payee: defaultBilling.fk_id_payee,
        fk_id_customer_type: defaultBilling.fk_id_customer_type,
        commercial_conditions: defaultBilling.commercial_conditions,
      })
    } else {
      // New
      setForm({
        default_billing_name: null,
        default_value: '0.00',
        fk_id_currency: null,
        max_installments: null,
        fk_id_affiliation: null,
        fk_id_program: null,
        fk_id_service: null,
        fk_id_payment_method: null,
        fk_id_payee: null,
        fk_id_customer_type: null,
        commercial_conditions: null
      })
    }
  }, [defaultBilling, duplicating, setForm])
  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables])
  const selectedAffiliationOption = useMemo(() => {
    if (!form.fk_id_affiliation) return null
    return affiliationOptions.find(_ => _.id === form.fk_id_affiliation) || null
  }, [affiliationOptions, form.fk_id_affiliation])
  const isFormSubmittable = useMemo(() => {
    if (!Boolean(form.default_billing_name) || !Boolean(form.default_value) || !Boolean(form.fk_id_currency) || !Boolean(form.max_installments) || !Boolean(form.fk_id_affiliation) || !Boolean(form.fk_id_program) || !Boolean(form.fk_id_service) || !Boolean(form.fk_id_payment_method) || !Boolean(form.fk_id_payee) || !Boolean(form.fk_id_customer_type)) return false

    return true
  }, [form])

  const handleSubmit = useCallback(async () => {
    setDisableSave(true)

    const updating = Boolean(form.id)
    const _toast = toast(updating ? 'Updating Default Billing...' : 'Creating Default Billing...', { toastId: 'toast-default-billings-handle-submit', isLoading: true })

    try {
      const { data } = await axios({
        method: updating ? 'PUT' : 'POST',
        url: updating ? `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings/${form.id}` : `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          default_billing_name: form.default_billing_name,
          default_value: form.default_value,
          fk_id_currency: form.fk_id_currency,
          max_installments: Number(form.max_installments),
          fk_id_affiliation: form.fk_id_affiliation,
          fk_id_program: form.fk_id_program,
          fk_id_service: form.fk_id_service,
          fk_id_payment_method: form.fk_id_payment_method,
          fk_id_payee: form.fk_id_payee,
          fk_id_customer_type: form.fk_id_customer_type,
          commercial_conditions: form.commercial_conditions,
        }
      })

      console.log(data)

      toastSuccess(_toast, updating ? 'Default Billing updated.' : 'New Default Billing created.')

      refreshTable()

      refreshLookupTables(['defaultBilling'])

      closePanel()
    } catch (err) {
      console.log(err)

      toastError(_toast, updating ? 'Could not update Default Billing.' : 'Could not create Default Billing.')
    }

    setDisableSave(false)
  }, [form, closePanel, refreshTable, refreshLookupTables])

  const onDefaultValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // If the user selects everything and press backspace
    if (!e.target.value.length) {
      setForm((form) => ({ ...form, default_value: '0.00' }))
      return
    }
    // If the user types something funny
    if (Number.isNaN(Number(e.target.value))) return

    const reGroups = /(-?)(\d+)\.?(\d+)?/g.exec(e.target.value)

    if (reGroups) {
      const possibleNegativeSign = reGroups[1] || ''
      const integerPart = reGroups[2]
      const decimalPart = reGroups[3]

      // Removing all left zeros
      const noLeftZeros = `${integerPart}${decimalPart}`.replace(/^0+/g, '')

      // Parsing to two decimal digits
      const twoDecimalDigits = (parseFloat(possibleNegativeSign + (noLeftZeros || '0')) / 100).toFixed(2)

      setForm((form) => ({ ...form, default_value: twoDecimalDigits }))
      return
    }
  }, [setForm])

  const handleSelectService = (serviceID: number) => {
    setForm(prev => {
      const newData = prev
      newData.fk_id_service = serviceID
      if (serviceID === 1) newData.max_installments = 12 // Tuition
      else if (serviceID === 2) newData.max_installments = 3 // Courseware
      else if (serviceID === 6) newData.max_installments = 1 // Enrollment
      else newData.max_installments = 1 // Any other service

      return { ...newData }
    })
  }

  const handleAutoName = useCallback(() => {
    if (lookupTables.loading) return ''

    setForm(prev => {
      const programInfo = lookupTables.program.find(pr => pr.id === Number(prev.fk_id_program))
      const schoolStage = lookupTables.schoolStage.find(ss => ss.id === programInfo?.fk_id_school_stage)
      const service = lookupTables.service.find(se => se.id === Number(prev.fk_id_service))

      const newName = `${schoolStage?.tag} - ${service?.option} - ${selectedYear === 'all' ? 'YEAR' : selectedYear}`

      return { ...prev, default_billing_name: newName }
    })
  }, [lookupTables, setForm, selectedYear])

  return <Grid container spacing={1}>
    <Grid item xs={7}>
      <TextField
        id='form-program'
        label='Program'
        select
        value={form.fk_id_program || ''}
        onChange={(e) => setForm({ ...form, fk_id_program: Number(e.target.value) })}
      >
        {
          lookupTables.program.map((_program, index) => <MenuItem key={index} id={`program-option-${_program.id}`} value={_program.id}>{_program.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={5}>
      <TextField
        id='form-service'
        label='Service'
        select
        value={form.fk_id_service || ''}
        onChange={(e) => handleSelectService(Number(e.target.value))}
      >
        {
          lookupTables.service.map((_service, index) => <MenuItem key={index} id={`service-option-${_service.id}`} value={_service.id}>{_service.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={6}>
      <TextField
        id='form-default-value'
        label='Gross Value'
        value={form.default_value}
        onChange={onDefaultValueChange}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        id='form-currency'
        label='Currency'
        select
        value={form.fk_id_currency || ''}
        onChange={(e) => setForm({ ...form, fk_id_currency: Number(e.target.value) })}
      >
        {
          lookupTables.currency.map((_currency, index) => <MenuItem key={index} id={`currency-option-${_currency.id}`} value={_currency.id}>{_currency.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={4}>
      <TextField
        id='form-max-installments'
        label='Max Installments'
        value={form.max_installments || ''}
        onChange={(e) => setForm({ ...form, max_installments: Number.isNaN(Number(e.target.value)) ? form.max_installments : Number(e.target.value) })}
      />
    </Grid>
    <Grid item xs={8}>
      <Autocomplete
        id='form-affiliation'
        renderInput={props => <TextField {...props} label='Affiliation' />}
        options={affiliationOptions}
        value={selectedAffiliationOption}
        onChange={(e, newValue) => setForm(prev => ({ ...prev, fk_id_affiliation: newValue ? newValue.id : null, fk_id_customer_type: newValue?.id === 1 ? 2 : prev.fk_id_customer_type }))}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        id='form-payment-method'
        label='Payment Method'
        select
        value={form.fk_id_payment_method || ''}
        onChange={(e) => setForm({ ...form, fk_id_payment_method: Number(e.target.value) })}
      >
        {
          lookupTables.paymentMethod.map((_paymentMethod, index) => <MenuItem key={index} id={`payment-method-option-${_paymentMethod.id}`} value={_paymentMethod.id}>{_paymentMethod.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-payee'
        label='Payee'
        select
        value={form.fk_id_payee || ''}
        onChange={(e) => setForm({ ...form, fk_id_payee: Number(e.target.value) })}
      >
        {
          lookupTables.company.map((payee, index) => <MenuItem key={index} id={`payee-option-${payee.id}`} value={payee.id}>{payee.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={5}>
      <TextField
        id='form-customer-type'
        label='Payer Type'
        select
        value={form.fk_id_customer_type || ''}
        onChange={(e) => setForm({ ...form, fk_id_customer_type: Number(e.target.value) })}
        disabled={form.fk_id_affiliation === 1}
      >
        {
          lookupTables.customerType.map((_customerType, index) => <MenuItem key={index} id={`customer-type-option-${_customerType.id}`} value={_customerType.id}>{_customerType.option}</MenuItem>)
        }
      </TextField>
    </Grid>

    <Grid item xs={12} />
    {
      form.id ?
        <Grid item xs={1.5}>
          <TextField
            id='form-id'
            label='ID'
            value={form.id || ''}
            disabled
          />
        </Grid>
        :
        null
    }
    <Grid item xs={3}>
      <Button
        id='btn-auto-name'
        fullWidth
        sx={{ height: '100%' }}
        startIcon={<DriveFileRenameOutline />}
        onClick={handleAutoName}
        disabled={!form.fk_id_program || !form.fk_id_service}
      >
        Auto Name
      </Button>
    </Grid>
    <Grid item xs={form.id ? 7.5 : 9}>
      <TextField
        id='form-name'
        label='Name'
        value={form.default_billing_name || ''}
        onChange={(e) => setForm({ ...form, default_billing_name: e.target.value })}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='form-commercial-conditions'
        label='Commercial Conditions'
        value={form.commercial_conditions || ''}
        onChange={(e) => setForm({ ...form, commercial_conditions: e.target.value })}
        multiline
        rows={4}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        id='button-save'
        disabled={disableSave || !isFormSubmittable}
        sx={{ height: 60 }}
        startIcon={form.id ? <Update /> : <Save />}
        onClick={handleSubmit}
        fullWidth
      >
        {form.id ? 'Update' : 'Create'}
      </Button>
    </Grid>
  </Grid>
}