import { useMemo } from "react"
import { toast } from "react-toastify"
import { toastError, toastSuccess, toastWarning } from "../assets/customToasts"
import { Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material"
import { NewEmailProps } from "./types"
import { Code } from "@mui/icons-material"
import style from './email.module.css'
import { useMsal } from "@azure/msal-react"

export default function NewEmail({ isHTML, email, setEmail, username, sendRules }: NewEmailProps) {

    const { accounts } = useMsal()

    const handleHTMLFile = (event: React.ChangeEvent<HTMLInputElement>) => {

        const file = event?.target?.files?.[0]

        const _toast = toast('Loading HTML file...', { isLoading: true })

        if (file && file.type === "text/html") {

            try {
                const reader = new FileReader()

                reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
                    if (loadEvent.target?.result) {
                        setEmail((_email) => ({
                            ..._email,
                            message: loadEvent?.target?.result as string
                        }))
                    }
                }

                reader.readAsText(file)
                toastSuccess(_toast, 'HTML Loaded')
            } catch (error) {
                toastError(_toast, 'Could not load HTML file...')
            }
            return
        }

        toastWarning(_toast, "Only html files are allowed.")
    }

    const senderOptions = useMemo(() => {
        const data = [{
            value: 'marketing@wk12.org',
            label: 'Marketing <marketing@wk12.org>',
            roles: ['dev', 'secretary', 'finances', 'admin', 'academic']
        },
        {
            value: 'portal@wk12.org',
            label: 'Portal <portal@wk12.org>',
            roles: ['dev', 'secretary', 'admin', 'academic']
        },
        {
            value: 'registrar@wk12.org',
            label: 'Registrar <registrar@wk12.org>',
            roles: ['dev', 'secretary', 'admin']
        },
        {
            value: 'academic@wk12.org',
            label: 'Academic <academic@wk12.org>',
            roles: ['dev', 'academic', 'admin']
        }]

        const result = data.filter((_) => accounts.length ? accounts[0].idTokenClaims?.roles?.some(role => _.roles.includes(role)) : true)

        return result

    }, [accounts])

    return <Grid item xs={12}>
        <Grid container spacing={1}>
            <Grid item xs={isHTML ? 6 : 12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper sx={{
                            background: `${sendRules.color}`,
                            fontSize: '17px',
                            fontWeight: 500,
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0px 18px',
                            alignItems: 'center'
                        }}>
                            <p>{sendRules.message}</p>
                            {sendRules.icon}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Sender'
                            id={'email-input-sender'}
                            value={email.sender}
                            select
                            onChange={(e) => setEmail({ ...email, sender: e.target.value })}
                        >
                            <MenuItem value={username}>You {`<${username}>`}</MenuItem>
                            {
                                senderOptions.map((item, index) => <MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id={'email-input-subject'}
                            label={'Subject'}
                            variant="outlined"
                            value={email.subject}
                            onChange={(e) => setEmail({ ...email, subject: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ position: 'relative' }}>
                        <TextField
                            id={'email-input-message'}
                            label={'Message'}
                            multiline
                            rows={16}
                            variant="outlined"
                            value={email.message}
                            onChange={(e) => setEmail({ ...email, message: e.target.value })}
                            inputProps={{ className: style.customInputWithScrollbar }}
                        />
                        <div style={{ position: 'absolute', bottom: 10, right: isHTML ? 30 : 10 }}>
                            <Button
                                id='button-upload-html'
                                variant="contained"
                                component="label"
                                startIcon={<Code />}
                            >
                                Upload HTML
                                <input
                                    type="file"
                                    accept="text/html"
                                    hidden
                                    onClick={(e) => (e.currentTarget.value = "")} //forcing input value change every clicked
                                    onChange={handleHTMLFile}
                                />
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {
                isHTML ?
                    <Grid item xs={6}>
                        <div
                            className={style.htmlContentBox}
                            dangerouslySetInnerHTML={{ __html: email.message }}
                        >

                        </div>
                    </Grid>
                    :
                    null
            }
        </Grid>
    </Grid>
}