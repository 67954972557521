// Functions shared by more than one component

import axios from "axios"
import { DateTime } from "luxon"
import { Dispatch, SetStateAction } from "react"

// Tries to transform a single digit number in two, if it isn't already
function twoDigits(str: string | number) {
  return ("0" + String(str)).slice(-2)
}

export const generatePassword = () => {
  return `wed@${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}`
}

// Converts the ISO Format to dates
export function convertDateToEST(date: string) {
  if (date) {
    // Parsing the ISO date
    const re = new RegExp(/(\d{4})-(\d{2})-(\d{2})/)
    const [year, month, day] = re.exec(date)!.slice(1, 4)

    return new Date(Number(year), Number(month) - 1, Number(day))
  }
  else return null
}

// Converts dates to the ISO Format
export function convertDateToUTC(date: any) {
  // Creates the ISO String for the correct date
  if (date) {
    // Parsing the user date to UTC
    const userDate = new Date(date)

    const year = userDate.getFullYear()
    const month = twoDigits(userDate.getMonth() + 1)
    const day = twoDigits(userDate.getDate())

    console.log(`${year}-${month}-${day}T00:00:00Z`)

    return `${year}-${month}-${day}T00:00:00Z`
  }
  else return null
}


export async function handleDelete(route: string, id: number, refreshTable: () => void) {
  await axios({
    method: 'DELETE',
    url: `${process.env.REACT_APP_SIS_BACKEND_URL}/${route}/${id}`,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
    }
  })
    .then(() => {
      refreshTable()
    })
}

export function renderCurrency(idCurrency: number | string) {
  if (typeof idCurrency === 'number') {
    switch (idCurrency) {
      case 1:
        return '$'
      case 2:
        return '€'
      case 3:
        return '£'
      case 4:
        return '$U'
      case 5:
        return 'R$'
      case 6:
        return '₱'
      default:
        return ''
    }
  } else {
    switch (idCurrency) {
      case 'USD':
        return '$'
      case 'EUR':
        return '€'
      case 'GBP':
        return '£'
      case 'URY':
        return '$U'
      case 'BRL':
        return 'R$'
      case 'PHP':
        return '₱'
      default:
        return ''
    }
  }
}

export function writeMonthTag(month: number) {
  switch (month) {
    case 1:
      return 'Jan'
    case 2:
      return 'Feb'
    case 3:
      return 'Mar'
    case 4:
      return 'Apr'
    case 5:
      return 'May'
    case 6:
      return 'Jun'
    case 7:
      return 'Jul'
    case 8:
      return 'Aug'
    case 9:
      return 'Sep'
    case 10:
      return 'Oct'
    case 11:
      return 'Nov'
    case 12:
      return 'Dec'
  }
}

export default function limitStringLength(limit: number, text: string) {

  if (text.length > limit) return text.substring(0, limit) + '...'
  return text
}

export function writeTwoDigitsMonth(month: number) {
  const monthString = month.toString()
  const _monthString = monthString.length === 1 ? '0' + monthString : monthString
  return _monthString
}

export function writeOneDecimalsString(value: string | number) {
  if (value === 0 || value === '0') return '0'
  if (typeof value === 'number') value = value.toString()

  if (value.includes('.')) {
    let [integers, decimals] = value.split('.')
    return `${integers}.${decimals}`
  } else {

    return `${value}.0`
  }
}

export function writeTwoDecimalsString(value: string | number, addDecimalsComma?: boolean, removeHundredsComma?: boolean) {
  if (typeof value === 'number') value = value.toString()
  value = convertNumberToString(value)
  let newValue = value
  if (removeHundredsComma) newValue = value.replace(',', '')

  if (newValue.includes('.')) {
    let integers = newValue.split('.')[0]
    let decimals = newValue.split('.')[1]
    if (decimals.length > 2) decimals = decimals[0] + decimals[1];
    if (decimals.length === 1) decimals = decimals + '0'
    return `${integers}${addDecimalsComma ? ',' : '.'}${decimals}`
  }
  return `${newValue}${addDecimalsComma ? '' : '.'}00`
}

export type RenderNumberWithTwoDecimals = {
  value: string | number,
  decimalsSeparator?: ',' | '.',
  hundredsSeparator?: ',' | '.' | null,
}

export function renderNumberWithTwoDecimals({ value, decimalsSeparator = '.', hundredsSeparator = ',' }: RenderNumberWithTwoDecimals) {
  if (typeof value === 'number') value = value.toString()
  let newValue = value.replaceAll(',', '')
  let newIntegers = ''
  if (newValue.includes('.')) {
    let integers = newValue.split('.')[0]
    let decimals = newValue.split('.')[1]

    if (integers.length <= 3) newIntegers = integers
    if (integers.length <= 6) newIntegers = `${integers.slice(0, -3)}${hundredsSeparator}${integers.slice(-3)}`
    if (integers.length <= 9) newIntegers = `${integers.slice(0, -6)}${hundredsSeparator}${integers.slice(-6, -3)}${hundredsSeparator}${integers.slice(-3)}`

    let newDecimals = ''
    if (decimals.length > 2) newDecimals = decimals[0] + decimals[1];
    if (decimals.length === 1) newDecimals = decimals + '0'
    if (!decimals.length) newDecimals = '00'
    return `${integers}${decimalsSeparator}${newDecimals}`
  }

  console.log('Got here...?')
  if (newValue.length <= 3) newIntegers = newValue
  if (newValue.length <= 6) newIntegers = `${newValue.slice(0, -3)}${hundredsSeparator}${newValue.slice(-3)}`
  if (newValue.length <= 9) newIntegers = `${newValue.slice(0, -6)}${hundredsSeparator}${newValue.slice(-6, -3)}${hundredsSeparator}${newValue.slice(-3)}`
  console.log(newIntegers)
  return `${newIntegers}${decimalsSeparator}00`
}

export function handleMoneyInput(e: any) {
  handleNumberInput(e)

  const value = e.target.value
  if (value === '-') return '-'
  let negativeValue = value.includes('-') ? true : false
  let newValue = value.replace(/[^\d]/g, '');
  newValue = newValue.replace(/^[0+],?/g, '')
  if (!newValue.length) return ''
  if (newValue.length === 1) return `${negativeValue ? '-' : ''}0.0${newValue}`
  if (newValue.length === 2) return `${negativeValue ? '-' : ''}0.${newValue}`
  if (newValue.length < 6) return `${negativeValue ? '-' : ''}${newValue.slice(0, -2)}.${newValue.slice(-2)}`
  if (newValue.length < 9) {
    return `${negativeValue ? '-' : ''}${newValue.slice(0, -5)},${newValue.slice(-5, -2)}.${newValue.slice(-2)}`
  }
  return `${negativeValue ? '-' : ''}${newValue.slice(0, -8)},${newValue.slice(-8, -5)},${newValue.slice(-5, -2)}.${newValue.slice(-2)}`
}

export function convertNumberToString(value: string | number) {
  if (typeof value === 'number') value = value.toString()

  // Return an empty string if an empty value was sent as an argument
  if (!value) return ''
  if (value === '') return ''
  let valueString = value.toString()

  // Remove all commas (thousands and millions separator)
  if (valueString.includes(',')) {
    valueString = valueString.replaceAll(',', '')
  }

  // Function to add comma separators to positive and negative numbers
  const addCommas = (integers: string) => {
    let negativeInteger = false
    if (integers[0] === '-') {
      integers = integers.replace('-', '')
      negativeInteger = true
    }
    if (integers.length > 3 && integers.length <= 6) {
      const thousands = integers.slice(0, -3)
      const hundreds = integers.slice(-3)
      return `${negativeInteger ? '-' : ''}${thousands},${hundreds}`
    } else if (integers.length > 6) {
      const millions = integers.slice(0, -6)
      const thousands = integers.slice(-6, -3)
      const hundreds = integers.slice(-3)
      return `${negativeInteger ? '-' : ''}${millions},${thousands},${hundreds}`
    } else {
      return `${negativeInteger ? '-' : ''}${integers}`
    }
  }

  if (value.toString().includes('.')) {
    const integers = valueString.split('.')[0]
    let decimals = valueString.split('.')[1]
    // Round decimals to 2 digits
    if (decimals.length > 2) decimals = Math.round(Number(decimals.slice(0, 2))).toString()
    return `${addCommas(integers)}.${(decimals.length === 1 ? `${decimals}0` : decimals)}`
  } else {
    return `${addCommas(valueString)}`
  }
}

export function convertStringToNumber(value: string | number) {
  if (typeof value === 'number') value = value.toString()

  if (value === null || value === undefined) return 0
  // Handle neutral values returned by moneyInputToString()
  if (value === '00.00' || value === '-00.00') return 0
  // Negative number
  const negative = value.includes('-') ? true : false
  value = value.replaceAll('-', '')
  // Decimals
  if (value.includes('.')) {
    const integers = value.split('.')[0].replaceAll(',', '')
    let decimals = value.split('.')[1]
    if (decimals.length > 2) {
      decimals = decimals[0] + decimals[1]
    }
    return Number(`${negative ? '-' : ''}${integers}.${decimals}`)
  }
  // Integer
  const integers = value.replaceAll(',', '')
  return Number(`${negative ? '-' : ''}${integers}`)
}

export function handleNumberInput(e: any, except?: ('Control' | 'Backspace' | 'Delete' | 'Period' | 'Comma' | 'Tab' | 'ArrowLeft' | 'ArrowUp' | 'ArrowRight' | 'ArrowDown' | 'Minus' | 'NumpadSubtract')[]) {
  let permittedKeys = ['Control', 'Backspace', 'Delete', 'Period', 'Comma', 'Tab', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Minus', 'NumpadSubtract']
  // Remove permitted keys if passed on the 'except' array
  if (except && except.length) {
    except.forEach(exception => {
      permittedKeys = permittedKeys.filter(key => key !== exception)
    })
  }
  const regexAllNumbers = /[0-9 | -]/
  const regexPositiveNumbers = /[0-9]/
  const allowNegatives = permittedKeys.includes('Minus') || permittedKeys.includes('NumpadSubtract')
  if (permittedKeys.includes(e.code)) {
    return
  } else if (e.ctrlKey) {
    // Allow Copy and Paste commands
    if (e.key === 'c' || e.key === 'v') return
    return e.preventDefault();
  } else if (allowNegatives && !regexAllNumbers.test(e.key)) {
    e.preventDefault();
  } else if (!regexPositiveNumbers.test(e.key)) {
    e.preventDefault();
  }
}

export function removeCommasFromNumber(value: string) {
  if (value.includes(',')) {
    value.replaceAll(',', '')
    return Number(value)
  }

  return Number(value)
}

export function convertISOtoDateFormat(date: string) {
  const justDate = date.split('T')[0]
  const [year, month, day] = justDate.split('-')
  return `${day}-${writeMonthTag(Number(month))}-${year}`
}

export function addFiveBusinessDays(isoDate: string) {
  // This function adds five business days to a given date
  const date = DateTime.fromISO(isoDate)

  // Saturday, Sunday and Monday
  if (date.weekday === 1) return date.plus({ days: 5 })
  // Tuesday through Friday
  if ([2, 3, 4, 5].includes(date.weekday)) return date.plus({ days: 7 })
  // Saturday
  if (date.weekday === 6) return date.plus({ days: 7 })
  // Sunday
  if (date.weekday === 7) return date.plus({ days: 6 })
}

export function getOrdinalFrom(n: number | string) {
  if (typeof n === 'string') n = Number(n)

  let ord = 'th';

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st';
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd';
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd';
  }

  return `${n}${ord}`;
}

export function toKebabCase(text: string) {
  const textFragments = text.split(' ')
  let newString = ''
  textFragments.forEach((fragment, counter) => {
    if (counter === 0) return newString = newString.concat(fragment.toLowerCase())
    newString = newString.concat(`-${fragment.toLowerCase()}`)
  })
  return newString
}

export type RequiredVouchers = {
  fk_id_voucher_type: number;
  quantity: number;
}

/** Get sections' required voucher type and quantity */
export const getRequiredVouchers = (fk_id_school_stage: number, credits: number, weeks: number): RequiredVouchers => {
  const newRequiredVouchers: RequiredVouchers = {
    fk_id_voucher_type: 0,
    quantity: 0
  }

  // Set voucher type based on school stage
  if (fk_id_school_stage === 4) newRequiredVouchers.fk_id_voucher_type = 1
  if (fk_id_school_stage === 3) newRequiredVouchers.fk_id_voucher_type = 2
  if (fk_id_school_stage === 2) newRequiredVouchers.fk_id_voucher_type = 3

  // Set required number of vouchers based on number of credits and weeks
  if (credits <= 0.5 && weeks === 18) {
    newRequiredVouchers.quantity = 1
  } else {
    newRequiredVouchers.quantity = 2
  }

  return newRequiredVouchers
}

export type balanceType = {
  fk_id_voucher_type: number,
  voucher_type_name: string,
  voucher_type_tag: string,
  balance: number
}

export type availableVouchersType = {
  fk_id_customer: number,
  customer_name: string,
  fk_id_affiliation: number,
  affiliation_name: string,
  beneficiary_balance: balanceType[],
  affiliation_balance: balanceType[]
}

export const availableVouchersEmpty: availableVouchersType = {
  fk_id_customer: 0,
  customer_name: '',
  fk_id_affiliation: 0,
  affiliation_name: '',
  beneficiary_balance: [],
  affiliation_balance: []
}

type GetAvailableVouchersParameters = {
  id: {
    type: 'fk_id_customer' | 'fk_id_student',
    fk_id: number | null
  },
  setAvailableVouchers: Dispatch<SetStateAction<availableVouchersType>>,
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const getAvailableVouchers = async ({ id, setAvailableVouchers, setLoading }: GetAvailableVouchersParameters) => {
  if (!id.fk_id) return

  setLoading(true)
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/vouchers/1?${id.type}=${id.fk_id}`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      }
    })

    console.log(data)
    setAvailableVouchers(data)
  } catch (err) {
    console.log(err)
  }

  setLoading(false)
}

type ConsumeVouchersParameters = {
  data: {
    beneficiaries: number[],
    fk_id_voucher_type: number,
    quantity: number,
    updated_by: string,
    description_complement?: string
  },
  onThen?: () => void,
  onCall?: () => void,
  onCatch?: () => void,
  onCleanup?: () => void,
}

export const consumeVouchers = async ({ data, onCall, onThen, onCatch, onCleanup }: ConsumeVouchersParameters) => {
  if (onCall) onCall()

  await axios({
    method: 'PUT',
    url: `${process.env.REACT_APP_SIS_BACKEND_URL}/vouchers/1`,
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
    },
    data: data
  })
    .then(response => {
      console.log(response)
      if (onThen) onThen()
    })
    .catch(err => {
      console.log(err)
      if (onCatch) onCatch()
    })

  if (onCleanup) onCleanup()
}

export type StatusColorsTypes = 'blue' | 'green' | 'red' | 'yellow' | 'magenta' | 'greenBlue' | 'strongRed' | 'strongYellow' | 'darkBlue' | 'grayBlue' | 'darkGreen' | 'darkPink' | 'lightPurple' | 'darkPurple' | 'darkRed' | 'orange' | 'strongBlue'

export function quickSort<T>(arr: T[], sortingKey?: keyof T | null, direction?: 'asc' | 'desc'): T[] {
  if (arr.length <= 1) return arr;

  const pivot = arr[0];
  let leftArr = [];
  let rightArr = [];

  for (let i = 1; i < arr.length; i++) {
    if (sortingKey) {
      if (direction === 'desc') {
        if (arr[i][sortingKey] >= pivot[sortingKey]) {
          leftArr.push(arr[i]);
        } else {
          rightArr.push(arr[i]);
        }
      } else {
        if (arr[i][sortingKey] < pivot[sortingKey]) {
          leftArr.push(arr[i]);
        } else {
          rightArr.push(arr[i]);
        }
      }
    } else {
      if (direction === 'desc') {
        if (arr[i] >= pivot) {
          leftArr.push(arr[i]);
        } else {
          rightArr.push(arr[i]);
        }
      } else {
        if (arr[i] < pivot) {
          leftArr.push(arr[i]);
        } else {
          rightArr.push(arr[i]);
        }
      }
    }
  }

  return [...quickSort(leftArr, sortingKey, direction), pivot, ...quickSort(rightArr, sortingKey, direction)];
}

export const searchByMultipleTerms = (searchTerms: string, valueToVerify: string) => {
  const searchTermsArray = (searchTerms).toUpperCase().split(' ')
  const parsedValueToVerify = (valueToVerify || '').toUpperCase()
  for (const searchTerm of searchTermsArray) {
    if (!parsedValueToVerify.includes(searchTerm)) return false
  }
  return true
}

export function parseSearchParams<T>(query: string): Record<keyof T, any> | {} {
  if (!query) return {}

  const _query = query.replace('?', '').split('&').map((_) => _.split('='))

  return Object.fromEntries(_query)
}

export const formatNumberToMoney = (value: number) => {
  const _value = value
    .toFixed(2)
    .split('')
    .reverse() // Reversing the array to apply the logic of inputting a comma at every nth place
    .map((_, index) => {
      // console.log(_, index, index > 2 && (index % 3) - 2 === 0)
      if (index > 3 && (index % 3) === 0 && _ !== '-') return `${_},` // 5th, 8th, 11th and onward, return with a comma
      return _
    })
    .reverse() // Reversing to see the correct number
    .join('')

  return _value
}

export const allowNumbers = (input: string) => {
  if (input === '') return 0

  if (/^[0-9]+$/g.test(input)) return Number(input)

  return NaN
}

export const allowFloat = (input: string) => {
  if (input === '') return 0

  if (/^[0-9.]+$/g.test(input)) return parseFloat(input)

  return NaN
}

export const allowMoneyFormat = (input: string) => {
  if (input === '') return 0

  if (/^[0-9.,-]+$/g.test(input)) return parseFloat(input.replace(/[,]/g, ''))

  return NaN
}

export const roundTwoDecimals = (input: number) => {
  return parseFloat(input.toFixed(2))
}

export const getDistinct = <T,>(array: T[], property: keyof T) => {
  return array.reduce((acc: (T[keyof T])[], curr: T) => {
    if (acc.includes(curr[property])) return acc
    else return [...acc, curr[property]]
  }, [])
}

export const convertOriginalToWedScore = (value: string, fk_id_country?: number | '') => {
  const valueToNumber = Number(value.replace(',', '.'))

  // Handle Brazilian transcripts
  if (fk_id_country === 24) {
    // BASE 10
    if (valueToNumber >= 6 && valueToNumber < 7) {
      // between 6 and 7
      return Math.floor(70 + 10 * (valueToNumber - 6))
    } else if (valueToNumber >= 7 && valueToNumber < 9) {
      // between 7 and 9
      return Math.floor(80 + 5 * (valueToNumber - 7))

    // BASE 100
    } else if (valueToNumber >= 60 && valueToNumber < 70) {
      // between 60 and 70
      return Math.floor(70 + 10 * ((valueToNumber / 10) - 6))
    } else if (valueToNumber >= 70 && valueToNumber < 90) {
      // between 70 and 90
      return Math.floor(80 + 5 * ((valueToNumber / 10) - 7))

    // Regular conversion
    } else {
      return valueToNumber <= 10 ? Math.round(valueToNumber * 10) : Math.round(valueToNumber)
    }
  } else {
    // Undefined country
    return valueToNumber <= 10 ? Math.round(valueToNumber * 10) : Math.round(valueToNumber)
  }
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}