import { Checkbox, Grid, TextField } from "@mui/material"
import { useMemo, useState } from "react"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import { ViewStudent } from "../students/StudentsSearch"
import { ViewFaculty } from "../faculty/FacultySearch"

type EmailRecipientsTable = {
  setRecipients: React.Dispatch<React.SetStateAction<number[]>>
  recipients: number[]
  data: (ViewFaculty | ViewStudent)[]
}

export default function EmailRecipientsTable({ data, recipients, setRecipients }: EmailRecipientsTable) {
  const [searchName, setSearchName] = useState<string>('')
  const columnWidth = [0.5, 2]
  const re = useMemo(() => new RegExp(searchName.toLowerCase(), 'i'), [searchName])

  const filterName = (row: ViewFaculty | ViewStudent) => {
    switch (searchName) {
      case '':
        return true
      default:
        return re.test('student_full_name' in row ? row.student_full_name.toLowerCase() : row.full_name.toLowerCase())
    }
  }

  const checkAllRows = (checked: boolean) => {
    setRecipients(() => {
      if (checked) return data.map((item) => item.id)

      return []
    })
  }

  const onSelectRow = (id: number, checked: boolean) => {
    setRecipients((prev) => {
      if (checked) return prev.concat(id)

      return prev.filter((_) => _ !== id)
    })
  }

  const selectedDataFirst = useMemo(() => {
    const selectedRecipients = data.filter((_) => recipients.includes(_.id))
    const nonSelectedRecipients = data.filter((_) => !recipients.includes(_.id))

    return [...selectedRecipients, ...nonSelectedRecipients]
  }, [data, recipients])

  return <>
    <Grid item xs={12}>
      <TextField label='Name' value={searchName} onChange={(e) => setSearchName(e.target.value)} />
    </Grid>
    <Grid item xs={12} maxHeight={'60vh'}>
      <Table>
        <HeaderRow>

          {
            data.length === 0 ?
              null
              :
              <Cell width={columnWidth[0]}>
                <Checkbox
                  id='select-all'
                  checked={recipients.length === data.length}
                  onChange={(e) => checkAllRows(e.target.checked)}
                  sx={{ transform: 'translateX(-6px)' }}
                />
              </Cell>
          }

          <Cell flexGrow><p>Name</p></Cell>
          <Cell width={columnWidth[1]}><p>E-mail</p></Cell>
        </HeaderRow>
        <BodyContainer>
          {
            selectedDataFirst.length ?
              selectedDataFirst.filter(filterName).map((_row, index) => <BodyRow key={index}>
                <Cell width={columnWidth[0]}>
                  <p>
                    <Checkbox
                      id={`select-row-${_row.id}`}
                      checked={recipients.includes(_row.id)}
                      onChange={(e) => onSelectRow(_row.id, e.target.checked)}
                      sx={{ transform: 'translateX(-6px)' }}
                    />
                  </p>
                </Cell>
                <Cell flexGrow>
                  <p>
                    {'student_full_name' in _row ? _row.student_full_name : _row.full_name}
                  </p>
                </Cell>
                <Cell width={columnWidth[1]}>
                  <p>
                    {_row.email}
                  </p>
                </Cell>
              </BodyRow>
              )
              :
              <BodyRow>
                <Cell><p>No recipients</p></Cell>
              </BodyRow>
          }
        </BodyContainer>
      </Table>
    </Grid>
  </>
}
