import { Button, Container, Grid, TextField, Typography } from '@mui/material'
import axios from 'axios'
import { useContext, useState } from 'react'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../assets/customToasts'

export default function TestManagement() {
  // General
  const [randomString, setRandomString] = useState<string>('')
  const links = {
    diplomat: {
      url: process.env.REACT_APP_DIPLOMAT_URL,
      token: process.env.REACT_APP_DIPLOMAT_TOKEN
    },
    nest: {
      url: process.env.REACT_APP_NEST_BACKEND_URL,
      token: process.env.REACT_APP_NEST_BACKEND_TOKEN
    },
    notifier: {
      url: process.env.REACT_APP_NOTIFIER_URL,
      token: process.env.REACT_APP_NOTIFIER_TOKEN
    },
    requests: {
      url: process.env.REACT_APP_REQUESTS_URL,
      token: process.env.REACT_APP_REQUESTS_TOKEN
    },
    sis: {
      url: process.env.REACT_APP_SIS_BACKEND_URL,
      token: process.env.REACT_APP_SIS_BACKEND_TOKEN
    }
  }
  // Hooks
  const { refreshLookupTables } = useContext(LookupTablesContext)

  const setup = async (backend: 'diplomat' | 'nest' | 'notifier' | 'requests' | `sis`, path: string) => {
    const _toast = toast('Running setup', { toastId: 'toast-setup', isLoading: true })

    try {
      await axios({
        method: 'GET',
        url: `${links[backend].url}/${path}?randomString=${randomString}`,
        headers: {
          Authorization: `Bearer ${links[backend].token}`
        }
      })

      await refreshLookupTables(['all'])

      toastSuccess(_toast, 'Setup successful.')
    } catch (err) {
      console.log(err)

      toastError(_toast, 'Error on setup.')
    }
  }

  const cleanup = async (backend: 'diplomat' | 'nest' | 'notifier' | 'requests' | `sis`, path: string) => {
    const _toast = toast('Running cleanup', { toastId: 'toast-cleanup', isLoading: true })

    try {
      await axios({
        method: 'GET',
        url: `${links[backend].url}/${path}?randomString=${randomString}`,
        headers: {
          Authorization: `Bearer ${links[backend].token}`
        }
      })

      await refreshLookupTables(['all'])

      toastSuccess(_toast, 'Cleanup successful.')
    } catch (err) {
      console.log(err)

      toastError(_toast, 'Error on cleanup.')
    }
  }

  return <Container>
    <br />
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id='textfield-random-string'
          label='Random String'
          value={randomString}
          onChange={(e) => setRandomString(e.target.value)}
        />
      </Grid>
      {/* Academic */}
      <Grid item xs={12}>
        <Typography>
          Academic
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-academic' onClick={() => cleanup(`sis`, 'academic-cleanup')}>
          Cleanup: Academic
        </Button>
      </Grid>
      {/* Student */}
      <Grid item xs={12}>
        <Typography>
          Student
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-student' onClick={() => setup(`sis`, 'students-setup')}>
          Setup: Student
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-student' onClick={() => cleanup(`sis`, 'students-cleanup')}>
          Cleanup: Student
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={6} />
      {/* Faculty */}
      <Grid item xs={12}>
        <Typography>
          Faculty
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-faculty' onClick={() => setup(`sis`, 'faculty-setup')}>
          Setup: Faculty
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-faculty' onClick={() => cleanup(`sis`, 'faculty-cleanup')}>
          Cleanup: Faculty
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={6} />
      {/* Affiliations */}
      <Grid item xs={12}>
        <Typography>
          Affiliations
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-affiliations' onClick={() => setup(`sis`, 'affiliations-setup')}>
          Setup: Affiliations
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-affiliations' onClick={() => cleanup(`sis`, 'affiliations-cleanup')}>
          Cleanup: Affiliations
        </Button>
      </Grid>
      {/* Reports */}
      <Grid item xs={12}>
        <Typography>
          Reports
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-reports' onClick={() => setup(`sis`, 'reports-setup')}>
          Setup: Reports
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-reports' onClick={() => cleanup(`sis`, 'reports-cleanup')}>
          Cleanup: Reports
        </Button>
      </Grid>
      {/* Billings */}
      <Grid item xs={12}>
        <Typography>
          Billings
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-billings' onClick={() => setup(`sis`, 'billings-setup')}>
          Setup: Billings
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-billings' onClick={() => cleanup(`sis`, 'billings-cleanup')}>
          Cleanup: Billings
        </Button>
      </Grid>
      {/* Vouchers */}
      <Grid item xs={12}>
        <Typography>
          Vouchers
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-vouchers' onClick={() => setup(`sis`, 'vouchers-setup')}>
          Setup: Vouchers
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-vouchers' onClick={() => cleanup(`sis`, 'vouchers-cleanup')}>
          Cleanup: Vouchers
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={6} />
      {/* Requests */}
      <Grid item xs={12}>
        <Typography>
          Nest
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-nest' onClick={() => setup(`requests`, 'sis-setup')}>
          Setup: Nest
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-nest' onClick={() => cleanup(`requests`, 'sis-cleanup')}>
          Cleanup: Nest
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={6} />
      {/* Requests */}
      <Grid item xs={12}>
        <Typography>
          Nest
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-common-request-student' onClick={() => setup(`requests`, 'sis-common-request-student-setup')}>
          Setup: Common Request Student
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-common-request-student' onClick={() => cleanup(`requests`, 'sis-common-request-student-cleanup')}>
          Cleanup: Common Request Student
        </Button>
      </Grid>
      {/* Spacer */}
      <Grid item xs={6} />
      {/* Canvas */}
      <Grid item xs={12}>
        <Typography>
          Canvas
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-canvas' onClick={() => setup(`diplomat`, 'canvas-logins-setup')}>
          Setup: Canvas
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-canvas' onClick={() => cleanup(`diplomat`, 'canvas-logins-cleanup')}>
          Cleanup: Canvas
        </Button>
      </Grid>
      {/* Tools */}
      <Grid item xs={12}>
        <Typography>
          Tools
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-tools' onClick={() => setup(`sis`, 'tools-setup')}>
          Setup: Tools
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-tools' onClick={() => cleanup(`sis`, 'tools-cleanup')}>
          Cleanup: Tools
        </Button>
      </Grid>
      {/* Enrollment Tools */}
      <Grid item xs={12}>
        <Typography>
          Enrollment Tools
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-enrollment-tools' onClick={() => setup(`sis`, 'enrollment-tools-setup')}>
          Setup: Enrollment Tools
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-enrollment-tools' onClick={() => cleanup(`sis`, 'enrollment-tools-cleanup')}>
          Cleanup: Enrollment Tools
        </Button>
      </Grid>
      {/* Course Progression Tools */}
      <Grid item xs={12}>
        <Typography>
          Course Progression Tools
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-course-progression-tools' onClick={() => setup(`sis`, 'course-progression-tools-setup')}>
          Setup: Course Progression Tools
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-course-progression-tools' onClick={() => cleanup(`sis`, 'course-progression-tools-cleanup')}>
          Cleanup: Course Progression Tools
        </Button>
      </Grid>
      {/* Credit Tools */}
      <Grid item xs={12}>
        <Typography>
          Credit Tools
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-credit-tools' onClick={() => setup(`diplomat`, 'sis-credit-tools-setup')}>
          Setup: Credit Tools
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-credit-tools' onClick={() => cleanup(`diplomat`, 'sis-credit-tools-cleanup')}>
          Cleanup: Credit Tools
        </Button>
      </Grid>
      {/* E-mails */}
      <Grid item xs={12}>
        <Typography>
          E-mails
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-setup-emails' onClick={() => setup(`notifier`, 'emails-setup')}>
          Setup: E-mails
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button id='button-cleanup-emails' onClick={() => cleanup(`notifier`, 'emails-cleanup')}>
          Cleanup: E-mails
        </Button>
      </Grid>
    </Grid>
  </Container>
}
