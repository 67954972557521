import axios from 'axios'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LookupTablesContext } from '../../context/LookupTablesContext'
import { Button, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { DeleteForever } from '@mui/icons-material'
import PreLoader from '../PreLoader'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../assets/customToasts'

export type ViewAffiliationProgramMap = {
  id: number
  fk_id_affiliation: number
  affiliation_name: string
  fk_id_program: number
  program_name: string
  fk_id_school_stage: number
  school_stage_name: string
}

export default function AffiliationProgramForm() {
  const { id } = useParams()
  const { lookupTables } = useContext(LookupTablesContext)
  const [program, setProgram] = useState<number | null>(null)
  const [message, setMessage] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [affiliationPrograms, setAffiliationPrograms] = useState<ViewAffiliationProgramMap[]>([])

  const unavailablePrograms = useMemo(() => affiliationPrograms.map((_) => _.fk_id_program), [affiliationPrograms])

  const refreshTable = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-programs?fk_id_affiliation=${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      if (data.length > 0) {
        setMessage('The programs selected for this affiliation are shown in Nest.')
        setAffiliationPrograms(data)
        setLoading(false)
        return
      }
      setMessage('The default Programs are shown in Nest')
      setLoading(false)
    } catch (error) {
      console.log(error)
      toast.error('Could not load affiliation programs.')
    }
  }, [])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const onSave = async () => {
    const _toast = toast('Saving affiliation program...', { toastId: 'toast-affiliation-program-save', isLoading: true })

    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-programs`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          fk_id_affiliation: id,
          fk_id_program: program
        }
      })
      toastSuccess(_toast, 'Program saved.')
      refreshTable()

    } catch (error) {
      toastError(_toast, 'Could not save program.')
      console.log(error)
    }
  }

  const onDelete = async (id: number) => {
    const _toast = toast('Deleting affiliation program...', { toastId: 'toast-affiliation-program-delete', isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliation-programs/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })
      toastSuccess(_toast, 'Program deleted.')
      refreshTable()

    } catch (error) {
      toastError(_toast, 'Could not delete program.')
      console.log(error)
    }
  }

  return <Grid container maxWidth={'sm'} marginY={.25} marginX={'auto'} spacing={1}>
    <Grid item xs={8}>
      <TextField id="select-program" label='Program' value={program || ''} onChange={(e) => setProgram(Number(e.target.value))} select>
        {
          [
            ...lookupTables.program
              .filter((row) => !unavailablePrograms.includes(row.id))
              .map((_program, index) => <MenuItem key={index} value={_program.id}>{_program.option}</MenuItem>)
          ]
        }
      </TextField>
    </Grid>
    <Grid item xs={4}>
      <Button id='button-add' disabled={!program} onClick={() => onSave()}>ADD</Button>
    </Grid>
    {message && <Grid item xs={12}>
      <Paper>
        <Typography>{message}</Typography>
      </Paper>
    </Grid>}
    <Grid item xs={12}>
      {loading ?
        <PreLoader height={'50vh'} />
        :
        <Table>
          <HeaderRow>
            <Cell width={6}><p>Program</p></Cell>
          </HeaderRow>
          <BodyContainer>
            {!affiliationPrograms.length && <BodyRow><Cell width={2}><p>No programs registered yet</p></Cell></BodyRow>}
            {affiliationPrograms.length ?
              affiliationPrograms.map((row, index) => {
                return (
                  <BodyRow key={index}>
                    <Cell width={6}><p>{row.program_name}</p></Cell>
                    <Cell width={1}>
                      <IconButton id={`button-delete-${row.id}`} onClick={() => onDelete(row.id)}>
                        <DeleteForever />
                      </IconButton>
                    </Cell>
                  </BodyRow>
                )
              })
              : null
            }
          </BodyContainer>
        </Table>
      }
    </Grid>
  </Grid>
}
